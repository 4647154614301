jQuery(document).ready(function($){


	/**
	*
	*	FORMATTER: #phone, #cell
	*		- Format the Contact Phone Number
	*
	* 	Params:
	* 		n/a
	*
	**/
	$('#phone').formatter({
	
		'pattern': '({{999}}) {{999}}-{{9999}}'
	
	});


});