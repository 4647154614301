jQuery(document).ready(function($){


	var Waybill = {

		//The Pie Chart Object
		chart: 		null,


		//Preset Densities
		densities: 	{			
			landfill: 	210,	
			wood: 		180,
			metal: 		600,
			cardboard: 	60,
			drywall: 	300,
			brush: 		830,
			concrete: 	830,
			misc: 		60
		},



		Upload: 	{


			dataType: 		'json',


			/**
			*
			*	progressall
			*		- Gets the File Upload Progress
			*
			*	Params:
			* 		- e: 		(Object) The Event Data
			* 		- data: 	(Object) The Upload Data
			*
			**/
			progressall: 	function( e , data ){

				//Get the Progress
		        var progress 	= parseInt(data.loaded / data.total * 100, 10);

		        //Get the Waybill Wrapper
				var parent 		= $( e.target ).parents('[data-waybill]');

				//Show the Wrapper
				$(parent).find('[data-upload-progress-wrapper]').css( 'display' , 'block' );

				//Set the Upload Width
				$(parent).find('[data-upload-progress-width]').css( 'width' , progress + '%' );

				//Set the Upload Length
				$(parent).find('[data-upload-progress-total]').html( progress );


			},




			/**
			*
			*	done
			*		- Triggered when the File Upload Finishes
			*
			*	Params:
			* 		- e: 		(Object) The Event Data
			* 		- data: 	(Object) The Upload Data
			*
			**/
			done: 			function( e , data ){

		        //Get the Waybill Wrapper
				var parent 		= $( e.target ).parents('[data-waybill]');

				//Show the Wrapper
				$(parent).find('[data-upload-progress-wrapper]').css( 'display' , 'none' );

				//Set the Upload Width
				$(parent).find('[data-upload-progress-width]').css( 'width' , '0%' );

				//Set the Upload Length
				$(parent).find('[data-upload-progress-total]').html( 0 );

				//
				switch( data.result.result ){

					case 1:

						//Save the Attachment ID
						$(parent).find('[name*="[attachment_id]"]').val( data.result.attachment_id );
					
						//Show the Delete PDF Button
						$(parent).find('[data-delete-pdf]').css( 'display' , 'block' );

						//Show the View PDF Button
						$(parent).find('[data-view-pdf]').attr( 'href' , data.result.url ).css( 'display' , 'block' );

						break;

					default:
						alert( 'An Error Occured: ' + data.result.error );
						break;

				}

			},




			/**
			*
			*	fail
			*		- Triggered when the File Upload Fails
			*
			*	Params:
			* 		- e: 		(Object) The Event Data
			* 		- data: 	(Object) The Upload Data
			*
			**/
			fail: 			function( e , data ){

				alert( 'An Error Occured: ' + data.errorThrown );

			}




		},


		/**
		*
		*	getName
		*		- Gets the Array Name of the Element
		*
		*	Params:
		* 		- el: 			(Element) The Element to check
		*
		**/
		getName: 	function( el ){

			return $( el ).attr('name').replace(/.*?\[([0-9]+)\]\[(.*?)\]/i,'$2');

		},





		/**
		*
		*	getAllData
		*		- Get All the Waybill Data
		*
		* 	Params:
		* 		- wrapper: 		(Object) The Wrapper Object Element
		*
		**/
		getAllData: 	function( wrapper ){

			//The Data Array
			var data = [],

			//The Total Data
			totals 		= {
				waste: {
					volume: 	0,
					mass: 		0
				},
				recycled: {
					volume: 	0,
					mass: 		0
				}
			};

			//Get Each Waybill
			$('[data-waybill-wrapper] [data-waybill]').each(function( index ){

				//Add a new Waybill
				data.push({});

				//Go through each field in each waybill
				$(this).find('input[type="number"][name^="waybill"]').each(function(){

					//Get the Field name
					var name = Waybill.getName( this ) ;

					//Preset the Data
					if( typeof data[ index ][ name ] === 'undefined' ) data[ index ][ name ] = {
						density: 	Waybill.densities[ name ],
						volume: 	null,
						mass: 		null,
						estimate: 	null
					} ;

					$(this).val( parseFloat( $(this).val() ) );

					data[ index ][ name ].estimate = parseFloat( $(this).val() || 0 );

				});

				//Get the Total Waybill Denstiy
				var total = 0;

				for( key in Waybill.densities ){

					total = total + ( ( data[ index ][ key ].estimate / 100 ) * data[ index ][ key ].density );

				}


				//Set the Total Mass ( eq to the Estimate)
				data[ index ].total.mass 	= data[ index ].total.estimate;

				//Get the Total Average Density
				data[ index ].total.density = total;

				//Set the Total Volume
				data[ index ].total.volume 	= parseFloat( data[ index ].total.mass / data[ index ].total.density || 0 );

				//Get the Volume & Mass for each field
				for( key in Waybill.densities ){

					//Set the Volume
					data[ index ][ key ].volume = data[ index ].total.volume * ( data[ index ][ key ].estimate / 100 );
				
					//Set the Mass
					data[ index ][ key ].mass 	= data[ index ][ key ].density * data[ index ][ key ].volume;

					//Preset the Totals Field
					if( typeof totals[ key ] === 'undefined' ) totals[ key ] = {
						volume: 	0,
						mass: 		0
					};

					//Increment the Total Volume
					totals[ key ].volume += data[ index ][ key ].volume;

					//Increment the Total Mass
					totals[ key ].mass += data[ index ][ key ].mass;

				}

				//Increment the Total Waste Volume
				totals.waste.volume += data[ index ].total.volume;

				//Increment the Total Waste Mass
				totals.waste.mass += data[ index ].total.mass;

			});

			//Increase Recycled if it doesn't go to the landfill
			for( key in Waybill.densities ){
				if( key != 'landfill' ){

					//Calculate the Recycled VOlume
					totals.recycled.volume += ( typeof totals[ key ] !== 'undefined' ? totals[ key ].volume : 0 );

					//Calculate the Recycled Mass
					totals.recycled.mass += ( typeof totals[ key ] !== 'undefined' ? totals[ key ].mass : 0 );

				}
			}

			//Calculate the Total Percentage of Waste Diverted
			totals.percent = Waybill.round( ( totals.recycled.mass / totals.waste.mass ) * 100 , 1 );

			//Preset the Leed
			totals.leed 	= ( totals.percent >= 95 ? 3 : ( totals.percent >= 75 ? 2 : ( totals.percent >= 50 ? 1 : 0 ) ) );

			//Return Data
			return {
				fields: data,
				totals: totals
			};

		},





		/**
		*
		*	round
		*		- Return the Rounded data fixed to deceminal point
		*
		*	Params:
		* 		- int: 			(INT) The Float to Round
		* 		- points: 		(INT) The Decimals Points to use
		*
		**/
		round: 		function( int , points ){

			return parseFloat( int.toFixed( points || 2 ) );

		},




		popup: 		function( url , errors ){

			$.confirm({
				title: 			'Select the Date Range',
				content: 		( errors ? '<div class="errors">' + errors + '</div>' : '' ) +
								'<input type="text" placeholder="yyyy-mm-dd" id="report-start" name="start" value="" />' +
								'<br />' +
								'<input type="text" placeholder="yyyy-mm-dd" id="report-end" name="end" value="" />',
				onOpen: 	function(){
		  			$('body').updatePolyfill();
				},
				confirmButton: 	'Generate Report',
				confirm: 		function(){
					$.post( url + window.location.href.match(/(\/([0-9]+))$/g)[0] , { start: $('#report-start').val() , end: $('#report-end').val() , }, function(data){
						if( data.result ){

							//Send Alert
							$.alert({
								title: 		'Success!',
								content: 	'The Report is being generated and will be emailed to you shortly!'
							});

						}else{

							var errors = [];

							for( key in data.errors ){
								errors.push( data.errors[ key ][0] );
							}

							//Show Error
							Waybill.popup( url , errors.join('<br />') , 'error' );

						}
					});
				}
			});
			$('#report-start,#report-end').formatter({
				'pattern': 	'{{9999}}-{{99}}-{{99}}',
			});
		}

	};








	/**
	*
	*	CLICK: [data-add-waybill]
	*		- Add a Waybill
	*
	* 	Params:
	* 		n/a
	*
	**/
	$('[data-add-waybill]').click(function(e){

		e.preventDefault();

		//Clone the Waybill
		$('[data-waybill-wrapper]').append( 
			$('[data-waybill]:first').clone().get(0).outerHTML
		);

		//Get the Index
		var index = $('[data-waybill]:last').index();

		//Reset all the Input Data
		$('[data-waybill]:last input[name]').each(function(){

			//Reset the Default Value
			$(this).val( '' );

			$(this).attr( 'name' , $(this).attr('name').replace('0' , index ) );  

		});
		
		//Update the Shim Polyfill
		$('body').updatePolyfill();

		//Set the Index
		$('[data-waybill]:last .index').html( index + 1 );

		//Setup the File Upload
		$('[data-waybill]:last [data-url]').fileupload( Waybill.Upload );

		//Hide the Buttons
		$('[data-waybill]:last').find('[data-view-pdf],[data-delete-pdf]').hide();

	});








	/**
	*
	*	CLICK: [data-delete-waybill]
	*		- Remove the Waybill
	*
	* 	Params:
	* 		n/a
	*
	**/
	$('body').on('click', '[data-waybill-delete]', function(e){

		e.preventDefault();

		$(this).parents('[data-waybill]').remove();

	});








	/**
	*
	*	CLICK: [data-add-pdf]
	*		- Trigger the Upload System
	*
	* 	Params:
	* 		n/a
	*
	**/
	$('body').on('click', '[data-add-pdf]', function(e){

		e.preventDefault();

		$(this).nextAll('[name="file"]').click();

	});








	/**
	*
	*	CLICK: [data-add-pdf]
	*		- Trigger the Upload System
	*
	* 	Params:
	* 		n/a
	*
	**/
	$('body').on('click', '[data-delete-pdf]', function(e){

		e.preventDefault();

		//Get the Waybill Wrapper
		var parent = $(this).parents('[data-waybill]');

		//Empty the Attachment ID and File
		$(parent).find('[name*="attachment_id"],[name="file"]').val('');

		//Hide PDF Buttons
		$(parent).find('[data-delete-pdf],[data-view-pdf]').hide();


	});






	/**
	*
	*	CHANGE: [name^="waybill"]
	*		- Update the Waybill Local & Total Data
	*	
	*	Params:
	* 		n/a
	*
	**/
	var completed = false; // Only run once
	$('body').on('change', 'input[type="number"][name^="waybill"]', function(e){
		if( completed ) return;

		//After the First Run, Set Completed
		completed = true;

		//Get the Parent
		var parent 	= $(this).parents('[data-waybill]'),

		//The Data points for the Chart
		points = [],

		//The Total Fields
		size = 0,

		//Get the Waybill data
		data = Waybill.getAllData();

		//Loop through each Data Field
		for( var i=0; i < data.fields.length; i++ ){

			//Loop through the densities
			for( key in Waybill.densities ){

				//Set the Percentage
				$('[data-waybill]:eq(' + i + ')').find('[data-' + key + '-amount]').html(Waybill.round( data.fields[ i ][ key ].mass ));

				//Add the Total Mass
				$('[data-' + key + '-total]').html(Waybill.round( data.totals[ key ].mass ));

			}

		}

		//Loop through the densities
		for( key in Waybill.densities ){

			//Increment the total fields
			size++;

			//Add the Total Mass
			$('[data-' + key + '-total]').html(Waybill.round( ( typeof data.totals[ key ] !== 'undefined' ? data.totals[ key ].mass : 0 ) ));

		}

		//Calculate the Total Recycled
		$('[data-recycled-total]').html(Waybill.round( data.totals.recycled.mass ));

		//Calculate the Total Waste
		$('[data-waste-total]').html(Waybill.round( data.totals.waste.mass ));

		//Calculate the Total Waste Diverted
		$('[data-waste-percent]').html( data.totals.percent );

		//Calculate the Total LEED Points
		$('[data-leed-total]').html( data.totals.leed + ' Point' + ( data.totals.leed > 1 ? 's' : '' ) );

		//Loop through the densities
		for( var key in Waybill.densities ){

			//Prepare the Data Points
			points.push({
				indexLabel: key.substr(0,1).toUpperCase() + key.substr(1) ,
				y: 			Waybill.round( ( data.totals[ key ] !== 'undefined' ? parseFloat( ( data.totals[ key ].mass / data.totals.waste.mass ) * 100 ) : 0 ) )
			});

		}

        //Set the Custom Colour Scheme
        CanvasJS.addColorSet('miniload',[

            //Landfill
            '#000',

            //Wood 
            '#ffcb9b',

            //Metal 
            '#cdffff',

            //Cardboard 
            '#fffd9d',

            //Drywall 
            '#FFF',

            //Brush 
            '#ffca27',

            //Concrete 
            '#c0c0c0',

            //Misc 
            '#e5b8b7'

        ]);

		//Setup the Chart
		new CanvasJS.Chart( 'chart' , {
            colorSet:   'miniload',
			data: 		[{
				type: 			'pie',
				showInLegend: 	true,
				legendText: 	'{indexLabel} {y}%',
				dataPoints: 	points

			}]

		}).render();

	});




	//Initialize the File Uploads
	$('[data-waybill] [data-url]').fileupload( Waybill.Upload );


	//Trigger the
	$('[data-waybill]:last input[type="number"][name^="waybill"]:last').change();


	//Hide the PDF Views for Waybills without Attachments
	$('[data-waybill] input[name*="attachment_id"]').each(function(){
		if( $(this).val() == '' ){

			$(this).parents('[data-waybill]').find('[data-delete-pdf],[data-view-pdf]').hide();

		}
	});


	//Sanity Check before Submitting
	$('[data-submit-waybill]').click(function(e){

		e.preventDefault();

		//Did we find an Error?
		var error 	= {
			result: 	true,
			log: 		''
		};

		$('[data-waybill]').each(function(index){

			var total = 0;

			//Make sure there is a Total Mass
			if(	$(this).find('[name*="[total]"]').val() == '' ){

				error.result = false;
				error.log 	 += 'Waybill No. ' + ( index + 1 ) + ' Requires a Total Mass' + "\n";

			}

			//Loop through each of the densities
			for( key in Waybill.densities ) {

				total += parseFloat( $(this).find('[name*="[' + key + ']"]').val() ) || 0;

			}

			//If the total % is less or greater than 100, show an error
			if( total < 100 || total > 100 ){

				error.result = false;
				error.log 	 += 'Waybill No. ' + ( index + 1 ) + ' Estimates do not equal 100%' + "\n";

			}

		});


		//Check if the Form found errors
		if( error.result ){ 

			//If no errors exist, submit the form
			$(this).parents('form').submit();

			return;

		}

		//Show the Errors
		alert( error.log );


	});






	//Generate a Waybill between a date range
	$('[data-export-job-site]').click(function(){
		Waybill.popup( $(this).data('export-job-site') );
	});


});