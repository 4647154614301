jQuery(document).ready(function($){


	var Report = {

		//The Pie Chart Object
		chart: 		null,


	};








	/**
	*
	*	CLICK: [name="frequency"]
	*		- Update the User's Frequency
	*
	* 	Params:
	* 		n/a
	*
	**/
	$('[name="frequency"]').change(function(e){

		e.preventDefault();

		//Send the Update
		$.post( $(this).data('request-url') , { frequency: $(this).val() } , function(response){

			console.log( response );

		});


	});








});