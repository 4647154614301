var App = {

	//Do Nothing

};







//Default functions
jQuery(document).ready(function($){






	/**
	*
	*	ON CLICK: [data-group-link]
	*		- Scroll to the closest user
	*
	* 	Params:
	* 		n/a
	*
	**/
	$('body').on('click','[data-group-link]', function(e){

		e.preventDefault();

		$(this).nearest( '.rolodex-add-field' ).animate({
			scrollTop: $(this).nearest( '.rolodex-add-field').find( '[data-group-id="' + $(this).data('group-link') + '"]' ).offset().top
		});

	});






	/**
	*
	*	ON CLICK: .rolodex-add-field [data-id]
	*		- Add an item to the assigned item list
	*
	* 	Params:
	* 		n/a
	*
	**/
	$('body').on('click','.rolodex-add-field [data-id]', function(e){

		e.preventDefault();

		$(this).nearest( '.rolodex-current' ).append(
			'<div class="' + $(this).data('type') + '">' +
				$.trim( $(this).html() ) + 
				'<i class="fa fa-minus-circle" aria-hidden="true"></i>' +
				'<input type="hidden" name="' + $(this).data('type') + '[]" value="' + $(this).data('id') + '" />' +
			'</div>'
		);

	});






	/**
	*
	*	ON CLICK: .rolodex-current .fa-minus-circle
	*		- Remove an Item from the Rolodex
	*
	* 	Params:
	* 		n/a
	*
	**/
	$('body').on('click','.rolodex-current .fa-minus-circle', function(e){

		e.preventDefault();

		$(this).parent().remove();

	});







	/**
	*
	*	$.ajaxSetup
	*		- Adds the CSRF Token to each Ajax Call
	*
	**/
    $.ajaxSetup({
        headers: { 'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content') }
    });






    /**
    *
    *	$.fn.nearest
    *		- Finds the closest element
    *
    * 	Params:
    * 		$this: 		(Object) The Starting (jQuery) Element
    * 		selector: 	(String) The Selector to Search Form
    *
    **/
    $.fn.nearest = function(selector){

    	var $el = this;

    	while( $el.find( selector ).length == 0 ){

    		$el = $el.parent();

    		if( $el[0].tagName == 'BODY' ){

    			throw selector + ' not found';

    			return null;

    		}

    	}

    	return $el.find( selector );

    }


});